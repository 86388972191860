@import '../../scss/imports';

.metric-list {
  table {
    width: 100%;

    td {
      padding: rem(12px 4px);
    }
  }

  .metric-list-column {
    span {
      display: block;
    }
  }

  .metric-list-row {
    border-bottom: 1px rgba($text-color, .1) solid;
  }

  .metric-list-cell-top {
    font-family: $font-demi;
    font-size: rem(18px);
  }

  .metric-list-cell-bottom {
    font-size: rem(12px);
    margin-top: rem(3px);
  }

  .metric-list-cell-top,
  .metric-list-cell-bottom {
    text-transform: capitalize;
    white-space: nowrap;

    &.no-cap {
      text-transform: none;
    }
  }
}

$timeout: 300ms;

.fade-enter {
  opacity: 0;
  transform: scaleY(0);
}

.fade-enter-active {
  opacity: 1;
  transform: scaleY(1);
  transition: all $timeout ease-in;
}

.fade-exit {
  opacity: 1;
  transform: scaleY(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scaleY(0);
  transition: all $timeout ease-in;
}