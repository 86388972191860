.focus-dialog {

  .md-title--toolbar {
    margin-top: 0;
  }

  .md-btn--toolbar {
    margin-bottom: rem(14px);
    margin-top: rem(14px);

    i {
      vertical-align: middle;
    }
  }

  .wrapper {
    padding-top: rem(100px);

    @include min-screen($landscape) {
      padding-top: rem(160px);
    }
  }
}
