@import '../../scss/imports';

#overview {
  .chart-card {
    margin-bottom: rem(16px);

    &.list {
      min-height: rem(451px);

      .empty-chart-container {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  #error-snackbar {
    p {
      margin-top: 0;
    }

    svg,
    span {
      vertical-align: middle;
    }
  }
}
