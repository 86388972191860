@import '../../scss/imports';

.chart-card {
  background: #FFF;
  border-radius: rem(12px);
  box-shadow: 0 6px 32px rgba(#828DA8, .2);
  min-height: rem(250px);
  padding: rem(16px);
  position: relative;

  .chart-card-label {
    font-family: $font-demi;
    font-size: rem(22px);
    margin: 0 0 rem(20px) 0;
  }

  .chard-card-action-container {
    margin-top: rem(16px);
    text-align: center;
    width: 100%;
  }
}

.top-metric {
  position: relative;

  .top-metric-value {
    color: $text-color;
    font-family: $font-numbers;
    font-size: rem(40px);
    margin: 0 0 rem(8px) 0;
    padding-top: rem(20px);
    text-shadow: none;
    transition: all 500ms ease-in-out;
    transform: none;

    .top-metric-prefix {
      display: inline-block;
      height: rem(50px);
      vertical-align: middle;
    }
  }

  .top-metric-label {
    font-size: rem(18px);
    margin: 0;
  }

  .top-metric-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.odometer {
  font-size: rem(32px);

  &.odometer-auto-theme {
    font-family: $font-numbers;
  }
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 500ms;
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 500ms;
}
